import { BoardCommonControllerService } from '@/__generated__/CommonApi';
import { Article } from '@/components/Article/types';
import BoardSearchBar from '@/components/BoardSearchBar';
import Button from '@/components/Button';
import EventCard from '@/components/Card/EventCard';
import Container from '@/components/Container';
import Row from '@/components/Grid/Row';
import MainTabButton from '@/components/MainTabButton';
import { BOARD_KIND_EVENT } from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
const SearchBarRow = styled(Row)`
  display: flex;
  margin-bottom: 32px;
  text-align: right;

  .ui-tabpanel-list {
    width: 310px;
    padding-right: 10px;
  }

  .EventSearchBar {
    width: calc(100% - 310xp);
  }

  ${breakpoint(800)} {
    .ui-tabpanel-list {
      width: 230px;
    }
  }

  ${breakpoint(`tablet`)} {
    flex-wrap: wrap;
    .ui-tabpanel-list,
    .EventSearchBar {
      width: 100%;
    }
    .ui-tabpanel-list {
      margin-bottom: 24px;
    }
  }
`;

const UiTabPanel = styled.ul`
  display: inline-flex;
`;
const UiTabPanelItem = styled.li`
  & + li {
    margin-left: 16px;
  }
`;

// 한 페이지당 보여줄 아이템 수
const pagePerCount = 10;

const EventPage: React.FC<PageProps> = ({ location }) => {
  const searchParams = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);
  const [events, setEvents] = useState<Article[]>([]);
  const [eventStatus, setEventStatus] = useState(`A`);
  const [searchKey, setSearchKey] = useState<string>(``);
  // 현재 페이지
  const [page, setPage] = useState(1);
  // 전체 글 수 (현재 조건기준)
  const [total, setTotal] = useState(0);
  const hasMore = useMemo(() => total > pagePerCount * page, [page, total]);

  const loadItem = useCallback(() => {
    BoardCommonControllerService.foListUsingGet({
      // 기본조건
      boardCategoryCode: [BOARD_KIND_EVENT],
      outDateDispYn: `Y`,
      // 가변조건
      eventStatus,
      pagePerCount,
      page,
      keyword: searchKey,
      optData2: searchParams.get(`optData2`) || ``,
    })
      .then(({ data, totalCount }) => {
        setEvents((prevEvents) => [...prevEvents, ...data]);
        setTotal(totalCount);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [eventStatus, page, searchParams, searchKey]);

  useEffect(() => {
    loadItem();
  }, [loadItem]);

  // 다음 페이지 로드
  const loadNextPage = () => {
    setPage(page + 1);
  };

  // 이벤트 페이지 변경
  const changeEventStatus = (status: string) => {
    if (eventStatus !== status) {
      setPage(1);
      setEvents([]);
      setEventStatus(status);
    }
  };

  const changeSearchKey = (keyword: string) => {
    if (searchKey !== keyword) {
      setPage(1);
      setEvents([]);
      setSearchKey(keyword);
    }
  };

  return (
    <LayoutWithTitle
      location={location}
      title="이벤트/캠페인"
      description="for every child, campaigns"
    >
      <Container
        css={`
          padding-top: 96px;
          ${breakpoint(640)} {
            padding-top: 48px;
          }
        `}
      >
        <SearchBarRow>
          <UiTabPanel className="ui-tabpanel-list">
            <UiTabPanelItem>
              <MainTabButton
                active={eventStatus === `A`}
                onClick={() => {
                  changeEventStatus(`A`);
                }}
              >
                전체
              </MainTabButton>
            </UiTabPanelItem>
            <UiTabPanelItem>
              <MainTabButton
                active={eventStatus === `B`}
                onClick={() => {
                  changeEventStatus(`B`);
                }}
              >
                진행중
              </MainTabButton>
            </UiTabPanelItem>
            <UiTabPanelItem>
              <MainTabButton
                active={eventStatus === `C`}
                onClick={() => {
                  changeEventStatus(`C`);
                }}
              >
                종료
              </MainTabButton>
            </UiTabPanelItem>
          </UiTabPanel>
          <BoardSearchBar
            className="EventSearchBar"
            initialKeyword=""
            onSearch={(value) => {
              changeSearchKey(value);
              changeEventStatus(`A`);
            }}
          />
        </SearchBarRow>
        <Row boardlist>
          <EventCard events={events} status={eventStatus} />
        </Row>
        {hasMore && (
          <ButtonContainer>
            <Button
              outline
              ico="moreArrow"
              width={352}
              onClick={() => {
                loadNextPage();
              }}
            >
              <span className="ico">더 보기</span>
            </Button>
          </ButtonContainer>
        )}
      </Container>
    </LayoutWithTitle>
  );
};

export default EventPage;
