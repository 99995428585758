import { Article } from '../Article/types';
import { BoardCardLayout } from '../Css/Board';
import LinkSafe from '../LinkSafe';
import { Tit } from '../Titles';
import { EVENT_LIST_URI } from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import dayjs from 'dayjs';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

interface EventProps {
  events: Article[];
  status: string;
}

const Thumbnail = styled.div<{ ended: boolean }>`
  border-radius: 0 0 40px 0;
  box-shadow: 12px 7px 20px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;

  ${({ ended: end }) => {
    if (end) {
      return `
        opacity: 0.6;
        .thumbnail-progress {
          color: #828385;
        }
      `;
    }
    return ``;
  }}

  .dec-wrap {
    ${Tit} {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      min-height: 62px;
      word-break: initial;
    }
  }
  ${breakpoint(800)} {
    .dec-wrap {
      ${Tit} {
        min-height: 50px;
      }
    }
  }
`;

const ThumbnailProgress = styled.span`
  color: #1cabe2;
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
`;
const ThumbnailDate = styled.span`
  display: block;
  margin-top: 16px;
`;
const EventCard: FC<EventProps> = ({ events, status }) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = `0${1 + date.getMonth()}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);

  const today = year + month + day;

  // 진행중 이벤트
  const ongoing = useMemo(
    () => events.filter((event) => event.eventStatusName?.includes(`진행중`)),
    [events],
  );
  // 종료 이벤트
  const finished = useMemo(
    () => events.filter((event) => event.eventStatusName?.includes(`종료`)),
    [events],
  );

  console.log(events);

  return (
    <>
      <BoardCardLayout className="event">
        {status === `A`
          ? events.map((event) => (
              <li key={event.boardIndexNumber}>
                <Thumbnail ended={event.eventStatusName === `종료`}>
                  <LinkSafe
                    to={
                      event.referUrl || EVENT_LIST_URI + event.boardIndexNumber
                    }
                    target="_blank"
                    className="link-item"
                  >
                    <div className="img-wrap">
                      <div
                        className="img-box"
                        css={`
                          background-image: url(${event.imgAttGrpNoTn});
                        `}
                      />
                    </div>
                    <div className="dec-wrap">
                      <ThumbnailProgress className="thumbnail-progress">
                        {event.eventStatusName}
                      </ThumbnailProgress>
                      <Tit size="s4-1">{event.subject}</Tit>
                      <ThumbnailDate>
                        {event.dispStartDate}
                        {` `}~{` `}
                        {event.dispEndDate === `2999.12.31`
                          ? `상시`
                          : event.dispEndDate}
                      </ThumbnailDate>
                    </div>
                  </LinkSafe>
                </Thumbnail>
              </li>
            ))
          : null}
        {status === `B`
          ? ongoing.map((event) => (
              <li key={event.boardIndexNumber}>
                <Thumbnail ended={event.eventStatusName === `종료`}>
                  <LinkSafe
                    to={
                      event.referUrl || EVENT_LIST_URI + event.boardIndexNumber
                    }
                    target="_blank"
                    className="link-item"
                  >
                    <div className="img-wrap">
                      <div
                        className="img-box"
                        css={`
                          background-image: url(${event.imgAttGrpNoTn});
                        `}
                      />
                    </div>
                    <div className="dec-wrap">
                      <ThumbnailProgress className="thumbnail-progress">
                        {event.eventStatusName}
                      </ThumbnailProgress>
                      <Tit size="s4-1">{event.subject}</Tit>
                      <ThumbnailDate>
                        {dayjs(event.dispStartDate).format(`YYYY-MM-DD`)}
                        {` `}~{` `}
                        {event.dispEndDate === `2999.12.31`
                          ? `상시`
                          : event.dispEndDate}
                      </ThumbnailDate>
                    </div>
                  </LinkSafe>
                </Thumbnail>
              </li>
            ))
          : null}
        {status === `C`
          ? finished.map((event) => (
              <li key={event.boardIndexNumber}>
                <Thumbnail ended={event.eventStatusName === `종료`}>
                  <LinkSafe
                    to={
                      event.referUrl || EVENT_LIST_URI + event.boardIndexNumber
                    }
                    target="_blank"
                    className="link-item"
                  >
                    <div className="img-wrap">
                      <div
                        className="img-box"
                        css={`
                          background-image: url(${event.imgAttGrpNoTn});
                        `}
                      />
                    </div>
                    <div className="dec-wrap">
                      <ThumbnailProgress className="thumbnail-progress">
                        {event.eventStatusName}
                      </ThumbnailProgress>
                      <Tit size="s4-1">{event.subject}</Tit>
                      <ThumbnailDate>
                        {dayjs(event.dispStartDate).format(`YYYY-MM-DD`)}
                        {` `}~{` `}
                        {event.dispEndDate === `2999.12.31`
                          ? `상시`
                          : event.dispEndDate}
                      </ThumbnailDate>
                    </div>
                  </LinkSafe>
                </Thumbnail>
              </li>
            ))
          : null}
      </BoardCardLayout>
    </>
  );
};
export default EventCard;
